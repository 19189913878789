import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import gif from "../assets/loopingcircles-3.gif";
import TableWithExport from "../components/TableWithExport";
import {
  cellCSS,
  gifStyling,
  headerCSS,
  requestPraveshOptions,
} from "../constants";
import { apiCall, apiSanchalanCall, sanchalanAxiosCall } from "../util/apiCall";
import ImageDialog from "../components/DialogSection/Reports/ImageDialog";
import ActionHistoryDialog from "../components/DialogSection/Reports/ActionHistoryDialog";
import ReportFilter from "../components/DialogSection/Reports/ReportFilter";
import moment from "moment";
import StatusDialog from "../components/DialogSection/Reports/StatusDialog";
import { FailureAlert, SuccessAlert } from "../util/Notifications";
import Querydescription from "../components/DialogSection/Reports/Querydescription";
import { Button } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

const HelpDesk = () => {
  const token = localStorage.getItem("token");
  const [service, setService] = React.useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [curentStatus, setCurrentStatus] = useState(false);
  const [status, setStatus] = useState("");
  const [collegeId, setCollegeId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [edit, setEdit] = useState(false);
  const [allComment, setAllComment] = useState(false);
  const [colleges, setColleges] = useState([]);
  const [serviceCache, setServiceCache] = useState({});
  const [selectedPageNo, setSelectedPageNo] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [metaData, setMetaData] = useState({
    totalCount: 0,
    totalPages: 1,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [filters, setFilters] = useState({
    selectCollegeId: collegeId,
    selectedSortBy: status,
  });


  // setService(serviceCache[selectedPageNo])
  const [loader, setLoader] = useState(false);
  const getServiceList = () => {
    if (serviceCache[selectedPageNo]) {
      setService(serviceCache[selectedPageNo]); 
      return;
    }
    setLoader(true);
    sanchalanAxiosCall
      .get(
        `/api/prashasan/service_history/?page=${
          selectedPageNo
        }&page_size=5${
          filters?.selectCollegeId && filters?.selectedSortBy
            ? `&status_filter=${filters?.selectedSortBy}&college_filter=${filters?.selectCollegeId}`
            : filters?.selectedSortBy
            ? `&status_filter=${filters?.selectedSortBy}`
            : filters?.selectCollegeId
            ? `&college_filter=${filters?.selectCollegeId}`
            : ""
        } `
      )

      .then((res) => {
        if (res?.data?.success) {
          let count = res?.data?.data?.count;
          let results = res?.data?.data?.results;
          setService(results);
          setPageLoading(false);
          setShowFilter(false);
          setMetaData({
            totalCount: count,
            totalPages: Math.ceil(count / 5),
          });
          setServiceCache((prevCache) => ({
            ...prevCache,
            [selectedPageNo]: results,
            1:prevCache[1] || results,
          }));
        } else {
          setService([]);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const [statusUpdate, setStatusUpdate] = useState({
    comment: null,
    new_status: null,
  });

  useEffect(() => {
    if (edit && currentRow) {
      setStatusUpdate({
        ...statusUpdate,
      });
    }
  }, [edit]);

  const isValidate = () => {
    if (!statusUpdate?.comment) {
      FailureAlert("Please Enter Comment");
      return false;
    } else if (!statusUpdate?.new_status) {
      FailureAlert("Please Enter Status");
      return false;
    }
    return true;
  };

  const [updateLoader, setUpdateLoader] = useState(false);
  const updateRow = (id) => {
    if (isValidate()) {
      setUpdateLoader(true);

      const requestOptionsPatch = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          comment: statusUpdate?.comment,
          new_status: statusUpdate?.new_status,
        }),
      };
      apiSanchalanCall(
        `/api/prashasan/service_history/${id}/`,
        requestOptionsPatch
      )
        .then((res) => {
          if (res.success) {
            SuccessAlert(res?.data?.message);
            setStatusUpdate(null);
            handleClose();
          } else {
            FailureAlert(res?.data?.message);
          }
          setUpdateLoader(false);
        })
        .catch((err) => {
          FailureAlert("Something went wrong");
          setUpdateLoader(false);
        });
    }
  };

  useEffect(() => {
    getServiceList();
  }, [selectedPageNo]);

  useEffect(() => {
    apiCall("/api/admin/college/get/", requestPraveshOptions).then((res) => {
      if (res.success) {
        let collegeNames = {};
        for (var i = 0; i < res.data.length; i++) {
          let obj = res.data[i];
          collegeNames[obj.college_id] = obj.college_name;
        }
        setColleges(res.data);
        localStorage.setItem("colleges", JSON.stringify(collegeNames));
        // setPageLoading(false);
      } else {
        FailureAlert(res.error);
      }
    });
  }, []);

  const columns = [
    {
      title: "Module Name",
      field: "module_name",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => <div style={{ width: "120px" }}>{row?.module_name}</div>,
    },
    {
      title: "Ticket Id",
      field: "formatted_ticket_id",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div style={{ width: "120px" }}>{row?.formatted_ticket_id}</div>
      ),
    },
    {
      title: "Create Date",
      field: "create_time",
      filter: {
        firstRender: true,
        onClick: "remove-invoice",
      },
      headerStyle: headerCSS,
      cellStyle: cellCSS,

      render: (row) => (
        <div>
          {row?.create_time
            ? moment(row?.create_time).format("DD/MM/YYYY")
            : ""}
        </div>
      ),
    },

    {
      title: "User id",
      field: "user_id",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => <div>{row?.submitted_by?.user_id}</div>,
    },
    {
      title: "Full Name",
      field: "full_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => <div>{row?.submitted_by?.user_name}</div>,
    },
    {
      title: "Email",
      field: "email",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => <div>{row?.submitted_by?.user_email}</div>,
    },
    {
      title: "Phone number",
      field: "phone_number",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>{row?.submitted_by?.phone ? row?.submitted_by?.phone : "NA"}</div>
      ),
    },
    {
      title: "College Name",
      field: "college_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div style={{ width: "150px" }}>{row?.submitted_by?.college_name}</div>
      ),
    },
    {
      title: "Query description",
      field: "description",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div style={{ width: "120px" }}>
          <Querydescription query={row?.description} />
        </div>
      ),
    },
    {
      title: "Screenshot",
      field: "screenshot",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          <ImageDialog images={row?.images} />
        </div>
      ),
    },
    {
      title: "Status",
      field: "status",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => <div style={{ width: "150px" }}>{row?.status}</div>,
    },
    {
      title: "Last Update Date",
      field: "update_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          {row?.update_time
            ? moment(row?.update_time).format("DD/MM/YYYY")
            : ""}
        </div>
      ),
    },
    {
      title: "Action History",
      field: "history",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          <ActionHistoryDialog actionData={row?.action_history} />
        </div>
      ),
    },
    {
      title: "Last Update By",
      field: "update_by",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div>
          {row?.action_history?.map((item, index) => {
            if (index === row?.action_history?.length - 1) {
              return <div>{item?.user_name}</div>;
            }
            return;
          })}
        </div>
      ),
    },
    {
      title: "Take Action",
      field: "",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) => (
        <div style={{ width: "140px" }}>
          <Button
            disabled={row?.status === "CLOSED" ? true : false}
            variant="contained"
            style={{ boxShadow: "none", width: 150 }}
            onClick={() => {
              handleClickOpen();
              setCurrentRow(row);
              setEdit(true);
              setCurrentStatus(row?.status);
            }}
          >
            {row?.status === "CLOSED" ? "Closed" : "Take Action"}
          </Button>
        </div>
      ),
    },
  ];

  // useEffect(() => {}, [filters]);

  return (
    <>
      {pageLoading ? (
        <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
      ) : (
        <LoadingOverlay
          active={loadingOverlay}
          spinner
          text="Updating Details..."
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "5px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                width: "8rem",
                height: "2.5rem",
                marginBottom: 10,
                boxShadow: "none",
              }}
              onClick={() => {
                setShowFilter(true);
              }}
            >
              <span style={{ color: "#fff" }}> Filters </span>
            </Button>
          </div>
          <div>
            <TableWithExport
              title="Service Desk"
              data={service}
              columns={columns}
              fName="Selected_Companies"
              showNestedTable={true}
              selection={false}
              sorting={false}
              search={false}
              filtering={false}
              hasPaging={false}
            />
            <section style={{ float: "right", marginTop: "1rem" }}>
              <Pagination
                page={selectedPageNo || 1}
                onChange={(e, value) => {
                  setSelectedPageNo(value);
                }}
                count={metaData?.totalPages}
                color="primary"
              />
            </section>
          </div>
          <StatusDialog
            open={open}
            handleClickOpen={handleClickOpen}
            updateRow={updateRow}
            currentRow={currentRow}
            setCurrentRow={setCurrentRow}
            data={service}
            statusUpdate={statusUpdate}
            setStatusUpdate={setStatusUpdate}
            handleClose={handleClose}
            curentStatus={curentStatus}
            loading={updateLoader}
          />
        </LoadingOverlay>
      )}
      {showFilter && (
        <ReportFilter
          open={showFilter}
          handleClose={() => {
            setShowFilter(false);
          }}
          colleges={colleges}
          setCollegeId={setCollegeId}
          setStatus={setStatus}
          filters={filters}
          setFilters={setFilters}
          recruiter={service}
          loader={loader}
          getServiceList={getServiceList}
        />
      )}
    </>
  );
};

export default HelpDesk;
