import configs from "./config";

export const url = configs.apis.pravesh;

export const niyuktiUrl = configs.apis.niyukti;
export const gptUrl = configs.apis.gpt;
export const praveshUrl = configs.apis.pravesh;

export const getworkApp = configs.apis.getworkFrontEnd;

export const GETWORK_INFO = configs.apis.info;

export const sanchalanUrl = configs.apis.sanchalan;
export const sandeshUrl = configs.apis.sandesh;

export const prashasanUrl = configs.apis.prashasan;

export const GOOGLE_CLIENT_ID =
  "864459248480-290lhp44ijnm6kearq3vu15m344el56d.apps.googleusercontent.com";

export const GETWORK_ORG_DOMAIN = configs.apis.REACT_APP_GETWORK_ORG;

export const MANAGER_EMAIL = [
  "sumit@getwork.org",
  "getwork.prashasan@gmail.com",
];

export const INTERVIEW_STATUS = [
  {
    name: "SCHEDULED",
    id: "SCHEDULED",
  },
  {
    name: "ACTIVE",
    id: "ACTIVE",
  },
  {
    name: "COMPLETED",
    id: "COMPLETED",
  },
];

export const headerCSS = {
  backgroundColor: "#ebf5fa",
  fontWeight: "bold",
  fontSize: "14px",
  padding: "4px 15px",
  height: "50px",
  textAlign: "left",
};
export const headerCSSNew = {
  backgroundColor: "#ebf5fa",
  fontWeight: "bold",
  fontSize: "14px",
  padding: "4px 15px",
  height: "50px",
  textAlign: "center",
};

export const cellCSS = {
  fontSize: "13px",
  padding: "6px 15px",
  height: "50px",
  textAlign: "left",
};

export const gifStyling = {
  // marginTop: "10%",
  // marginLeft: "25%",
  position: "absolute",
  top: "120px",
  bottom: "0",
  left: "0",
  right: "0",
  margin: "auto",
  marginTop: "75px",
};

export const createIconStyling = {
  cursor: "pointer",
  height: "15px",
  width: "15px",
  marginLeft: "5px",
};

export const statusCodes = {
  1: "Applied",
  8: "Reviewed",
  2: "Shortlisted",
  7: "Interviewed",
  13: "Hired",
  14: "Rejected",
  17: "Offered",
  18: "Joined",
  19: "Wait-Listed",
};

export default function generateCsv(arr, name) {
  let str = "";
  arr.forEach((item, index) =>
    arr.indexOf(item) !== arr.length - 1
      ? (str += item[name] + ", ")
      : (str += item[name])
  );
  return str;
}
export const jobCategoryList = [
  {
    id: "HOT",
    name: "Hot Job",
  },
  {
    id: "URGENT",
    name: "Urgent Job",
  },
  {
    id: "PREMIUM",
    name: "Premium",
  },
  {
    id: "POPULAR",
    name: "Popular",
  },
  {
    id: "IJF_IIT_DELHI23",
    name: "IJF_IIT_DELHI23",
  },
  {
    id: "JOB_FAIR_JUNE_24",
    name: "JOB_FAIR_JUNE_24",
  },
  {
    id: "3RD_PARTY_JOBS",
    name: "3rd Party Jobs",
  },
  {
    id: "JOB_FAIR_NOV_DEC_24",
    name: "Job Fair Nov Dec 24",
  },
  {
    id: "JOB_FAIR_FEB_TRCAC_25",
    name: "Job Fair Feb Trcac 25",
  },
  {
    id: "JOB_FAIR_ASSAM_UNI",
    name: "JOB_FAIR_ASSAM_UNI",
  },
  {
    id: "JOB_FAIR_MANGALAYATAN",
    name: "JOB_FAIR_MANGALAYATAN",
  },
];

export const subsectionsToRemove = [
  "WELCM",
  "TRACK",
  "JOBD",
  "PUBJOB",
  "ACTIVE_PLAN",
  "ACTIVE_SLOT",
  "3PJOB",
  "STUDENT_HISTORY",
  "AI_CALLING_BOT",
];

export const CARD_URLS = {
  1: `/admin/all-colleges`,
  2: `/admin/all-colleges/user-created`,
  5: `/admin/all-companies`,
  6: `/admin/all-companies`,
  8: `/admin/open-jobs`,
  9: `/admin/open-jobs/off-campus`,
};

export const manager_URLS = {
  3: `/admin/all-colleges/user-created`,
  4: `/admin/all-colleges`,
  5: `/admin/all-companies`,
  6: `/admin/open-jobs`,
};

export const work_experiences = [
  { name: "0 years", value: "0" },
  { name: "1 year", value: "1" },
  { name: "2 years", value: "2" },
  { name: "3 years", value: "3" },
  { name: "4 years", value: "4" },
  { name: "5 years", value: "5" },
  { name: "6 years", value: "6" },
  { name: "7 years", value: "7" },
  { name: "8 years", value: "8" },
  { name: "9 years", value: "9" },
  { name: "10 years", value: "10" },
  { name: "11 years", value: "11" },
  { name: "12 years", value: "12" },
  { name: "13 years", value: "13" },
  { name: "14 years", value: "14" },
  { name: "15 years", value: "15" },
  { name: "16 years", value: "16" },
  { name: "17 years", value: "17" },
  { name: "18 years", value: "18" },
  { name: "19 years", value: "19" },
  { name: "20 years", value: "20" },
  { name: "21 years", value: "21" },
  { name: "22 years", value: "22" },
  { name: "23 years", value: "23" },
  { name: "24 years", value: "24" },
  { name: "25 years", value: "25" },
];

export const companySizes = [
  { label: "1-10", id: 1 },
  { label: "11-50", id: 2 },
  { label: "51-100", id: 3 },
  { label: "101-1000", id: 4 },
  { label: "1000+", id: 4 },
];

export const passingYears = [
  { year: "1990" },
  { year: "1991" },
  { year: "1992" },
  { year: "1993" },
  { year: "1994" },
  { year: "1995" },
  { year: "1996" },
  { year: "1997" },
  { year: "1998" },
  { year: "2000" },
  { year: "2001" },
  { year: "2002" },
  { year: "2003" },
  { year: "2004" },
  { year: "2005" },
  { year: "2006" },
  { year: "2007" },
  { year: "2008" },
  { year: "2009" },
  { year: "2010" },
  { year: "2011" },
  { year: "2012" },
  { year: "2013" },
  { year: "2014" },
  { year: "2015" },
  { year: "2016" },
  { year: "2017" },
  { year: "2018" },
  { year: "2019" },
  { year: "2020" },
  { year: "2021" },
  { year: "2022" },
  { year: "2023" },
  { year: "2024" },
  { year: "2025" },
  { year: "2026" },
  { year: "2027" },
  { year: "2028" },
  { year: "2029" },
  { year: "2030" },
];

export const requestNiyuktiOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
};

export const requestPraveshOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Token ${localStorage.getItem("token")}`,
  },
};

export const userType = 9;
export const userTypeName = "GW-Admin";

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
export const urlRegex =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const websiteRegex =
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;

export const websiteRegexNew =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;
export const latitudeRegex =
  /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
export const longitudeRegex =
  /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

export const educationType = [
  {
    education_type: 1,
    education_type_name: "Secondary",
  },

  {
    education_type: 3,
    education_type_name: "Graduation (UG)",
  },
  {
    education_type: 4,
    education_type_name: "Post Graduation (PG)",
  },

  {
    education_type: 5,
    education_type_name: "PhD",
  },
  {
    education_type: 6,
    education_type_name: "Diploma",
  },
  {
    education_type: 7,
    education_type_name: "Institute Certification",
  },
];

export const roundtime = [
  { label: "15 min", val: 15 },
  { label: "20 min", val: 20 },
  { label: "25 min", val: 25 },
  { label: "30 min", val: 30 },
  { label: "35 min", val: 35 },
  { label: "40 min", val: 40 },
  { label: "45 min", val: 45 },
  { label: "50 min", val: 50 },
  { label: "55 min", val: 55 },
  { label: "1 hour 15 min", val: 75 },
  { label: "1 hour 30 min", val: 90 },
  { label: "1 hour 45 min", val: 105 },
  { label: "2 hour", val: 120 },
];

export const venueType = [
  { label: "Online", val: "ONLINE" },
  { label: "Offline", val: "OFFLINE" },
];

export const TEMPLATE_USER_TYPES = [
  { id: 1, name: "STUDENT" },
  { id: 2, name: "COLLEGE" },
  { id: 3, name: "COMPANY" },
  { id: 3, name: "ADMIN" },
];

export const CHANNEL_TYPES = [
  { id: 1, name: "SMS" },
  { id: 2, name: "WHATSAPP" },
  { id: 3, name: "EMAIL" },
  { id: 4, name: "APP_NOTIFICATION" },
  { id: 5, name: "WEBAPP_NOTFICATION" },
];

export const DASHBOARD_VIEWS = {
  RECRUITER: "RECRUITER_VIEW",
  TEAM_LEAD: "TEAM_LEAD_VIEW",
  MANAGER: "MANAGER_VIEW",
};

// PRASHASAN JOB STATUS With STATUS ID

export const PREAPPLIED = 21;
export const APPLIED = 1;
export const WAIT_LISTED = 19;
export const GETWORK_SCREENED = 20;
export const INTERVIEWED = 7;
export const OFFERED = 17;
export const JOINED = 18;
export const REJECTED = 14;
export const HIRED = 13;
export const JOINED_BACKOUT = 22;
export const SELECTED_BACKOUT = 23;
export const INTERVIEW_BACKOUT = 24;
export const OFFERED_BACKOUT = 25;
export const VENDOR_UPLOADED = 26;
export const VENDOR_APPLIED = 27;
export const MATURED_REVENURE = 29;
export const HR_SCREEN_BACKOUT = 44;
export const HR_SCREEN_REJECTED = 51;
export const HR_DUPLICATE = 48;
export const HR_USEFUL_IN_FUTURE = 49;

export const PRASHASAN_GW_SCREENING_PROCESS = [APPLIED];
export const PRASHASAN_GW_SCREENING_REJECTED = [30];
export const PRASHASAN_GW_SCREENING_WAITLISTED = [31];
export const PRASHASAN_CLIENT_SCREENING_PROCESS = [GETWORK_SCREENED];
export const PRASHASAN_CLIENT_SCREENING_REJECT = [32];
export const PRASHASAN_CLIENT_SCREENING_HOLD = [33];
export const PRASHASAN_CLIENT_SCREENING_DUPLICATE = [34];
export const PRASHASAN_INTERVIEW_REJECTED = [REJECTED];
export const PRASHASAN_INTERVIEW_INPROCESS = [INTERVIEWED];
export const PRASHASAN_INTERVIEW_HOLD = [35];
export const PRASHASAN_INTERVIEW_FEEDBACK = [36];
export const PRASHASAN_INTERVIEW_BACKOUT = [INTERVIEW_BACKOUT];
export const PRASHASAN_OFFER_IN_PROCESS = [HIRED];
export const PRASHASAN_OFFER_HOLD = [WAIT_LISTED];
export const PRASHASAN_OFFER_BACKOUT = [SELECTED_BACKOUT];
export const PRASHASAN_JOINING_IN_PROCESS = [OFFERED];
export const PRASHASAN_JOINING_BACKOUT = [OFFERED_BACKOUT];
export const PRASHASAN_JOINING_HOLD = [37];
export const PRASHASAN_INVOICE_INPROCESS = [JOINED];
export const PRASHASAN_INVOICE_BACKOUT = [JOINED_BACKOUT];
export const PRASHASAN_INVOICE_PAYMENT_RECEIVED = [MATURED_REVENURE];
export const PRASHASAN_INVOICE_RAISED = [JOINED_BACKOUT];
