import { makeStyles, Typography, useTheme, Button, CircularProgress } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { DASHBOARD_VIEWS } from "../../constants";
import { formatDateObject } from "../../util/utility";
import GraphAreaSkeleton from "./GraphAreaSkeleton";
import GraphWithTableThree from "./GraphWithTableThree";
import ReportTable from "./ReportTable";
import TeamLeadTable from "./TeamLead/TeamLeadTable";

function TeamLaadSection({
  exportLoader,
  skeletonGraphLoader,
  color,
  colors,
  secondColor,
  thirdColor,
  yAxis,
  xAxis,
  heading,
  tableTitle,
  graphData,
  currentView,
  filter,
  handleExportGraph,
}) {
  //const classes = useStyles();
  const theme = useTheme();
  // const alert = useAlert();
  const [TabId, setTabId] = useState(1);
  const [tabData, setTabData] = useState(null);

  function handleChange(id) {
    // setValue(id);
    setTabId(id);
  }

  const [loading, setLoading] = useState(false);

  // const [personName, setPersonName] = React.useState([]);
  // MenuProps.PaperProps.className = classes.selectOptions; //had to be here to use hook, and menu uses popper

  // const handleChangeField = (event) => {
  //   setPersonName(event.target.value);
  // };

  const chartHelper = () => {
    if (tabData) {
      const labels = [];
      const data = [];
      const color = [];
      for (let key of tabData) {
        labels.push(key.name);
        data.push(key.value);
        color.push(key.color);
      }

      return {
        labels: labels,
        data: data,
        color: color[0],
        yAxis: yAxis,
        xAxis: xAxis,
        heading: heading,
      };
    }
  };

  const data = [
    {
      id: 1,
      title: <div style={{ display: "flex", alignItems: "center" }}>Graph</div>,
      tab: (
        <>
          <GraphWithTableThree
            colors={colors}
            graphData={graphData}
            tableTitle={tableTitle}
          />
        </>
      ),
    },
    {
      id: 2,
      title: <div style={{ display: "flex", alignItems: "center" }}>Table</div>,
      tab: (
        <div style={{ maxHeight: "300px", overflow: "scroll" }}>
          {currentView === DASHBOARD_VIEWS.RECRUITER ? (
            <ReportTable
              chartData={chartHelper()}
              color={color}
              tableTitle={tableTitle}
              tableData={graphData}
            />
          ) : (
            <TeamLeadTable
              chartData={chartHelper()}
              color={color}
              tableTitle={tableTitle}
              tableData={graphData}
            />
          )}
        </div>
      ),
    },
    {
      id: 3,
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {exportLoader ? <CircularProgress size={20} color="inherit" /> : "Export in Excel"}
        </div>
      ),
      tab: null,
    },
  ];

  const memoizedData = useMemo(() => {
    return data;
  }, [skeletonGraphLoader]);

  return (
    <>
      {!skeletonGraphLoader ? (
        <div
          style={{
            border: "1px solid #b0b6ba",
            borderRadius: "8px",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row-reverse",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography variant="h6" style={{ fontWeight: "600" }}>
                {`${tableTitle} (${formatDateObject(
                  filter?.dateRange[0]
                )} to ${formatDateObject(filter?.dateRange[1])})`}
              </Typography>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#fff",
                    //   padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      border: "1px solid #E1DFDF",
                      borderRadius: "5px",
                    }}
                  >
                    {data.map((item, id) => (
                      <ul
                        style={{
                          padding: 0,
                          display: "flex",
                          listStyleType: "none",
                          flexWrap: "wrap",
                          border: "1px solid #E1DFDF",
                          textAlign: "center",
                        }}
                        id="myTab"
                        data-tabs-toggle="#myTabContent"
                        role="tablist"
                      >
                        <li
                          role="presentation"
                          onClick={() => {
                            if (item.id === 3) {
                              handleExportGraph(graphData?.graphId);
                            } else {
                              handleChange(item.id);
                            }
                          }}
                        >
                          <div
                            style={{
                              padding: "6px",
                              cursor: "pointer",
                              borderRadius: item.id === 3 ? "0px" : "5px",
                              color:
                                item.id === 3
                                  ? "#000"
                                  : TabId === item.id
                                  ? "#000"
                                  : "#747677",
                              backgroundColor:
                                item.id === 3
                                  ? "#fff"
                                  : TabId === item.id
                                  ? "#fff"
                                  : "#F3F2F2",
                                  display: "flex",
                              width: "120px",
                              height: "36px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {item.title}
                          </div>
                        </li>
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {data.map((item, id) =>
            item?.tab ? (
              <div id="myTabContent">
                <div
                  style={{
                    display: TabId === item.id ? "block " : "none",
                    marginTop: "20px",
                  }}
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  {item.tab}
                </div>
              </div>
            ) : null
          )}
        </div>
      ) : (
        <GraphAreaSkeleton />
      )}
    </>
  );
}

export default TeamLaadSection;
