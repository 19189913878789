import {
  AppBar,
  Box,
  makeStyles,
  Tab,
  Tabs,
  useTheme,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReportsGraphSection from "../../components/Dashboard/ReportsGraphSection";
import { DASHBOARD_VIEWS } from "../../constants";
import { niyuktiAxiosGetReq } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import {
  getNumberOfDaysCurrMonth,
  getMonthFirstDate,
} from "../../util/utility";
import { faO } from "@fortawesome/free-solid-svg-icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F5F5F5",
    width: "100%",
  },
}));

const SELECTED_PARAM_NAME = {
  1: "LINEUP",
  2: "FOOTFALL",
  3: "SELECTION",
  4: "JOINING",
};

function NewDasboard() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  // let initialView

  const userData = JSON.parse(localStorage.getItem("user-data"));
  useLayoutEffect(() => {
    const allowedRoles = ["RECRUITER", "TEAM_LEAD", "MANAGER"];
    if (!userData || !allowedRoles.includes(userData.admin_user_details)) {
      history.push("/admin/welcome_to_getwork");
    }
    // setInitialView()
  }, [history]);

  const [initialView, setInitialView] = useState(
    DASHBOARD_VIEWS[userData?.admin_user_details]
  );

  const [value, setValue] = React.useState(0);

  const [tabOn, setTabOn] = useState(initialView === DASHBOARD_VIEWS.MANAGER);
  const [cardId, setCardId] = useState(1);

  const initialFilter = {
    dateRange: [
      getMonthFirstDate(),
      new Date(
        new Date().setDate(
          getMonthFirstDate().getDate() + getNumberOfDaysCurrMonth() - 1
        )
      ),
    ],
    user: null,
  };
  const [filter, setFilter] = useState(initialFilter);

  const [errorScreenGraph, setErrorScreenGraph] = useState(false);
  const [errorScreenTable, setErrorScreenTable] = useState(false);

  const [skeletonInitialLoader, setSkeletonInitialLoader] = useState(true);
  const [skeletonGraphLoader, setSkeletonGraphLoader] = useState(true);

  const [currentView, setCurrentView] = useState(initialView);

  const [recruitersList, setRecruitersList] = useState([]);
  const [teamLeadsList, setTeamLeadsList] = useState([]);

  const [cardCounts, setCardCounts] = useState({});
  const [graphData, setGraphData] = useState({
    revenue_y_axis: [],
    x_axis: [],
    y_axis: [],
    colors: [],
  });

  const [exportLoader, setExportLoader] = useState(false);

  function formatDateParams(dateRange) {
    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    }

    const startDateString = formatDate(new Date(dateRange[0]));
    const endDateString = formatDate(new Date(dateRange[1]));

    const paramsString = `start_date=${startDateString}&end_date=${endDateString}`;

    return paramsString;
  }

  useEffect(() => {
    const getAPIURL = () => {
      if (currentView === initialView) {
        if (currentView === DASHBOARD_VIEWS.MANAGER) {
          const url = `/job/admin/performance/get/?param_name=MANAGER&section_param_name=${
            SELECTED_PARAM_NAME[cardId]
          }&${formatDateParams(filter?.dateRange)}`;
          return url;
        } else if (currentView === DASHBOARD_VIEWS.TEAM_LEAD) {
          const url = `/job/admin/performance/get/?param_name=TEAM_LEAD&section_param_name=${
            SELECTED_PARAM_NAME[cardId]
          }&team_lead_id=${userData?.id}&${formatDateParams(
            filter?.dateRange
          )}`;
          return url;
        } else if (currentView === DASHBOARD_VIEWS.RECRUITER) {
          const url = `/job/admin/performance/get/?param_name=RECRUITER&section_param_name=${
            SELECTED_PARAM_NAME[cardId]
          }&recruiter_id=${userData?.id}&${formatDateParams(
            filter?.dateRange
          )}`;
          return url;
        }
      } else {
        if (currentView === DASHBOARD_VIEWS.RECRUITER) {
          if (initialView === DASHBOARD_VIEWS.TEAM_LEAD) {
            const url = `/job/admin/performance/get/?param_name=TEAM_LEAD&section_param_name=${
              SELECTED_PARAM_NAME[cardId]
            }&team_lead_id=${userData?.id}&recruiter_id=${
              filter?.user?.id
            }&${formatDateParams(filter?.dateRange)}`;
            return url;
          } else {
            const url = `/job/admin/performance/get/?param_name=RECRUITER&section_param_name=${
              SELECTED_PARAM_NAME[cardId]
            }&recruiter_id=${filter?.user?.id}&${formatDateParams(
              filter?.dateRange
            )}`;
            return url;
          }
        }
        if (currentView === DASHBOARD_VIEWS.TEAM_LEAD) {
          const url = `/job/admin/performance/get/?param_name=TEAM_LEAD&section_param_name=${
            SELECTED_PARAM_NAME[cardId]
          }&team_lead_id=${filter?.user?.id}&${formatDateParams(
            filter?.dateRange
          )}`;
          return url;
        }
      }
    };
    if (!errorScreenGraph)
      niyuktiAxiosGetReq(getAPIURL())
        .then((res) => {
          if (res?.data?.success) {
            const data = res?.data?.data;

            setCardCounts(data?.count);

            setGraphData({
              revenue_y_axis: data?.revenue_y_axis,
              x_axis: data?.x_axis,
              y_axis: data?.y_axis,
              colors: data?.colors,
            });
            setErrorScreenGraph(false);
          } else {
            setErrorScreenGraph(true);
          }
          setSkeletonInitialLoader(false);
          setSkeletonGraphLoader(false);
        })
        .catch((err) => {
          setSkeletonInitialLoader(false);
          setSkeletonGraphLoader(false);
          setErrorScreenGraph(true);
        });
  }, [value, cardId, filter, errorScreenGraph]);

  useEffect(() => {
    const getRecruitersList = () => {
      niyuktiAxiosGetReq(`/job/admin/performance/filter/?param_name=RECRUITER`)
        .then((res) => {
          if (res?.data?.success) {
            const data = res?.data?.data;
            setRecruitersList(data);
          }
        })
        .catch((err) => {
          FailureAlert("Error fetching recruiters list");
        });
    };
    const getTeamLeadsList = () => {
      niyuktiAxiosGetReq(`/job/admin/performance/filter/?param_name=TEAM_LEAD`)
        .then((res) => {
          if (res?.data?.success) {
            const data = res?.data?.data;
            setTeamLeadsList(data);
          }
        })
        .catch((err) => {
          FailureAlert("Error fetching team leads list");
        });
    };
    if (initialView === DASHBOARD_VIEWS.TEAM_LEAD) {
      getRecruitersList();
    }
    if (initialView === DASHBOARD_VIEWS.MANAGER) {
      getRecruitersList();
      getTeamLeadsList();
    }
  }, []);

  const [tableDataLoader, setTableDataLoader] = useState(true);
  const [selectedPageNo, setSelectedPageNo] = useState(1);
  const [metaData, setMetaData] = useState({
    totalCount: 0,
    totalPages: 1,
  });
  const [inProcessTableData, setInProcessTableData] = useState([]);

  useEffect(() => {
    const getAPIURL = () => {
      if (initialView === DASHBOARD_VIEWS.MANAGER) {
        const url = `/job/admin/performance/profile_in_process/?param_name=MANAGER&page=${selectedPageNo}`;
        return url;
      } else if (initialView === DASHBOARD_VIEWS.TEAM_LEAD) {
        const url = `/job/admin/performance/profile_in_process/?param_name=TEAM_LEAD&team_lead_id=${userData?.id}&page=${selectedPageNo}`;
        return url;
      } else if (initialView === DASHBOARD_VIEWS.RECRUITER) {
        const url = `/job/admin/performance/profile_in_process/?param_name=RECRUITER&recruiter_id=${userData?.id}&page=${selectedPageNo}`;
        return url;
      }
    };
    const getTableData = () => {
      // if (currentView === initialView) {
      niyuktiAxiosGetReq(getAPIURL())
        .then((res) => {
          if (res?.data?.success) {
            const data = res?.data?.data;
            setInProcessTableData(data?.results);

            const count = res?.data?.data?.count;
            setMetaData({
              totalCount: count,
              totalPages: Math.ceil(count / 10),
            });
            setErrorScreenTable(false);
          } else {
            setErrorScreenTable(true);
          }
          setTableDataLoader(false);
        })
        .catch((err) => {
          FailureAlert("Error fetching in process table data");
          setErrorScreenTable(true);
          setTableDataLoader(false);
        });
      // }
    };
    if (selectedPageNo < 1) {
      return;
    }
    if (!errorScreenTable) getTableData();
  }, [selectedPageNo, errorScreenTable]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      setCurrentView(DASHBOARD_VIEWS.RECRUITER);
      setFilter((prev) => ({
        ...prev,
        user: recruitersList[0],
      }));
      setSkeletonGraphLoader(true);
      setSkeletonInitialLoader(true);
    } else {
      setCurrentView(DASHBOARD_VIEWS.MANAGER);
      setFilter((prev) => ({
        ...prev,
        user: null,
      }));
      setSkeletonGraphLoader(true);
      setSkeletonInitialLoader(true);
    }
  };

  const handleExportGraph = (exportGraphId) => {
    const getAPIURL = () => {
      if (currentView === initialView) {
        if (currentView === DASHBOARD_VIEWS.MANAGER) {
          const url = `/job/admin/performance/get/?param_name=MANAGER&section_param_name=${
            SELECTED_PARAM_NAME[cardId]
          }&${formatDateParams(
            filter?.dateRange
          )}&is_export=1&export_id=${exportGraphId}`;
          return url;
        } else if (currentView === DASHBOARD_VIEWS.TEAM_LEAD) {
          const url = `/job/admin/performance/get/?param_name=TEAM_LEAD&section_param_name=${
            SELECTED_PARAM_NAME[cardId]
          }&team_lead_id=${userData?.id}&${formatDateParams(
            filter?.dateRange
          )}&is_export=1&export_id=${exportGraphId}`;
          return url;
        } else if (currentView === DASHBOARD_VIEWS.RECRUITER) {
          const url = `/job/admin/performance/get/?param_name=RECRUITER&section_param_name=${
            SELECTED_PARAM_NAME[cardId]
          }&recruiter_id=${userData?.id}&${formatDateParams(
            filter?.dateRange
          )}&is_export=1&export_id=${exportGraphId}`;
          return url;
        }
      } else {
        if (currentView === DASHBOARD_VIEWS.RECRUITER) {
          if (initialView === DASHBOARD_VIEWS.TEAM_LEAD) {
            const url = `/job/admin/performance/get/?param_name=TEAM_LEAD&section_param_name=${
              SELECTED_PARAM_NAME[cardId]
            }&team_lead_id=${userData?.id}&recruiter_id=${
              filter?.user?.id
            }&${formatDateParams(
              filter?.dateRange
            )}&is_export=1&export_id=${exportGraphId}`;
            return url;
          } else {
            const url = `/job/admin/performance/get/?param_name=RECRUITER&section_param_name=${
              SELECTED_PARAM_NAME[cardId]
            }&recruiter_id=${filter?.user?.id}&${formatDateParams(
              filter?.dateRange
            )}&is_export=1&export_id=${exportGraphId}`;
            return url;
          }
        }
        if (currentView === DASHBOARD_VIEWS.TEAM_LEAD) {
          const url = `/job/admin/performance/get/?param_name=TEAM_LEAD&section_param_name=${
            SELECTED_PARAM_NAME[cardId]
          }&team_lead_id=${filter?.user?.id}&${formatDateParams(
            filter?.dateRange
          )}&is_export=1&export_id=${exportGraphId}`;
          return url;
        }
      }
    };
    setExportLoader(true);
    getAPIURL();
    niyuktiAxiosGetReq(getAPIURL())
      .then((res) => {
        if (res?.data?.success) {
          setExportLoader(false);
          SuccessAlert(res?.data?.message);
        } else {
          setExportLoader(false);
          FailureAlert("Something went wrong");
          
        }
      })
      .catch((err) => {
        setExportLoader(false);
        console.log("Error :", err);
      });
  };

  return (
    <>
      <div className={classes.root}>
        {tabOn ? (
          <>
            <AppBar
              position="static"
              color="default"
              style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Team Leads Report" {...a11yProps(0)} />
                <Tab label="Recruiters Report" {...a11yProps(1)} />
              </Tabs>
            </AppBar>

            <TabPanel value={value} index={0} dir={theme.direction}>
              <ReportsGraphSection
                exportLoader={exportLoader}
                skeletonInitialLoader={skeletonInitialLoader}
                setSkeletonInitialLoader={setSkeletonInitialLoader}
                skeletonGraphLoader={skeletonGraphLoader}
                setSkeletonGraphLoader={setSkeletonGraphLoader}
                cardId={cardId}
                setCardId={setCardId}
                currentView={currentView}
                setCurrentView={setCurrentView}
                initialView={initialView}
                value={value}
                filter={filter}
                setFilter={setFilter}
                recruitersList={recruitersList}
                teamLeadsList={teamLeadsList}
                cardCounts={cardCounts}
                graphData={graphData}
                inProcessTableData={inProcessTableData}
                selectedPageNo={selectedPageNo}
                setSelectedPageNo={setSelectedPageNo}
                tableDataLoader={tableDataLoader}
                setTableDataLoader={setTableDataLoader}
                metaData={metaData}
                userData={userData}
                errorScreenGraph={errorScreenGraph}
                errorScreenTable={errorScreenTable}
                selectedParamName={SELECTED_PARAM_NAME}
                handleExportGraph={handleExportGraph}
              />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <ReportsGraphSection
                exportLoader={exportLoader}
                skeletonInitialLoader={skeletonInitialLoader}
                setSkeletonInitialLoader={setSkeletonInitialLoader}
                skeletonGraphLoader={skeletonGraphLoader}
                setSkeletonGraphLoader={setSkeletonGraphLoader}
                cardId={cardId}
                setCardId={setCardId}
                currentView={currentView}
                setCurrentView={setCurrentView}
                initialView={initialView}
                value={value}
                filter={filter}
                setFilter={setFilter}
                recruitersList={recruitersList}
                teamLeadsList={teamLeadsList}
                cardCounts={cardCounts}
                graphData={graphData}
                inProcessTableData={inProcessTableData}
                selectedPageNo={selectedPageNo}
                setSelectedPageNo={setSelectedPageNo}
                tableDataLoader={tableDataLoader}
                setTableDataLoader={setTableDataLoader}
                metaData={metaData}
                userData={userData}
                errorScreenGraph={errorScreenGraph}
                errorScreenTable={errorScreenTable}
                selectedParamName={SELECTED_PARAM_NAME}
                handleExportGraph={handleExportGraph}
              />
            </TabPanel>
          </>
        ) : (
          <ReportsGraphSection
            exportLoader={exportLoader}
            skeletonInitialLoader={skeletonInitialLoader}
            setSkeletonInitialLoader={setSkeletonInitialLoader}
            skeletonGraphLoader={skeletonGraphLoader}
            setSkeletonGraphLoader={setSkeletonGraphLoader}
            cardId={cardId}
            setCardId={setCardId}
            currentView={currentView}
            setCurrentView={setCurrentView}
            initialView={initialView}
            value={value}
            filter={filter}
            setFilter={setFilter}
            recruitersList={recruitersList}
            teamLeadsList={teamLeadsList}
            cardCounts={cardCounts}
            graphData={graphData}
            inProcessTableData={inProcessTableData}
            selectedPageNo={selectedPageNo}
            setSelectedPageNo={setSelectedPageNo}
            tableDataLoader={tableDataLoader}
            setTableDataLoader={setTableDataLoader}
            metaData={metaData}
            userData={userData}
            errorScreenGraph={errorScreenGraph}
            errorScreenTable={errorScreenTable}
            setErrorScreenGraph={setErrorScreenGraph}
            setErrorScreenTable={setErrorScreenTable}
            selectedParamName={SELECTED_PARAM_NAME}
            handleExportGraph={handleExportGraph}
          />
        )}
      </div>
    </>
  );
}

export default NewDasboard;
